.txt_line {
  width: 100%;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flow-text {
  display: flex;

  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s;
}

.flow-text:hover .flow-wrap {
  animation-play-state: paused;
  cursor: pointer;
}

.flow-wrap {
  animation: textLoop 10s linear infinite;
  padding-right: 1.4881vw;
}

@keyframes textLoop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.text-shadow {
  text-shadow: -1.5px 0px var(--secondary-color), 1.5px 5px var(--secondary-color), 1.5px 5px var(--secondary-color),
    0px -1.5px var(--secondary-color);
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}
